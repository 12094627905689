<template>
  <div class="guide-vr">
    <div class="wrapper">
      <guide type="vr" title="VR STORE" image="re_guide_vr.png">
        톰그레이하운드 스토어를 VR로 자유롭게 둘러보고,<br />
        2021 FW 컬렉션도 쇼핑해보세요!
      </guide>
      <div class="button" @click="goNext()">
        <span>확인</span>
      </div>
    </div>
    <div class="bottom">
      <img
        class="tom-greyhound"
        src="../assets/re_main_logo_tomgreyhound.png"
        alt="Tom Greyhound"
      />
    </div>
  </div>
</template>

<script>
import Guide from "@/components/Guide.vue";

export default {
  components: {
    Guide,
  },
  methods: {
    goNext() {
      this.$router.push("/vr-store");
    },
  },
};
</script>

<style lang="scss" scoped>
.guide-vr {
  line-height: 1;
  height: 100%;
  .wrapper {
    height: 305.3px;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(50% - (305.3px / 2));
  }
  .button {
    margin: 0 auto;
    margin-top: 28px;
    width: 242px;
    height: 36px;
    font-family: "Noto Sans KR";
    font-size: 13px;
    line-height: 30px;
    cursor: pointer;
    border: 1px solid #000;
    border-radius: 3px;
    span {
      display: inline;
      vertical-align: middle;
    }
  }
  .bottom {
    position: fixed;
    width: 100%;
    bottom: 66px;
    .tom-greyhound {
      width: 113.2px;
    }
  }
}
</style>
